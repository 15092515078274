import {useState, useEffect} from 'react'
import {List, useListController} from 'react-admin'
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {TopToolbarActions} from '../../components/TopToolbar'
import {ShowQuestionDetails} from './ShowQuestionDetails'
import {usePagination} from '../../hooks/usePaginations'
import {Pagination} from '../../components/Pagination'
import {QuestionsDatagrid} from '../../components/questions/QuestionsDatagrid'
import SearchAndFilter from '../../components/questions/SearchAndFilter'


export const QuestionsList = props => {
  const { data } = useListController()
  const location = useLocation();
  const navigate = useNavigate();


  const [showDialog, setShowDialog] = useState(false)
  const [currentRecord, setRecord] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [dimensionFilter, setDimensionFilter] = useState('')
  const [deprecatedFilter, setDeprecatedFilter] = useState<boolean|undefined>()
  const {paginatedData, totalPages, currentPage, handlePageChange} = usePagination(10, filteredData)

  useEffect(() => {
    const query = queryString.parse(location.search);
    setSearchTerm(query.searchTerm as string || '');
    setDimensionFilter(query.dimensionFilter as string || '');
    setDeprecatedFilter(query.deprecatedFilter ? query.deprecatedFilter === 'true' : undefined);
    handlePageChange(query.page ? parseInt(query.page as string, 10) : 1);
    // eslint-disable-next-line
  }, [location.search]);

  const handleShowDetails = record => {
    setRecord(record)
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
    handlePageChange(1)
    updateQueryParams({ searchTerm: event.target.value, page: 1 });
  }

  const handleDimensionChange = (_, newValue: string) => {
    setDimensionFilter(newValue)
    handlePageChange(1)
    updateQueryParams({ dimensionFilter: newValue, page: 1 });
  }

  const handleDeprecatedChange = (_, newValue) => {
    setDeprecatedFilter(newValue?.value)
    handlePageChange(1)
    updateQueryParams({ deprecatedFilter: newValue?.value, page: 1 });
  }

  useEffect(() => {
    if (data) {
      let updatedData = [...data]

      if (searchTerm) {
        const searchLowerCase = searchTerm.toLowerCase()
        updatedData = updatedData.filter(
          item =>
            item.question.de.toLowerCase().includes(searchLowerCase) ||
            item.question.en.toLowerCase().includes(searchLowerCase) ||
            item.questionId.toLowerCase().includes(searchLowerCase)
        )
      }

      if (dimensionFilter) {
        updatedData = updatedData.filter(item => item.dimTransl?.en === dimensionFilter)
      }

    if (deprecatedFilter !== undefined ) {
        updatedData = updatedData.filter(item =>
            deprecatedFilter === true ? item?.deprecated === true : !item?.deprecated
        );
    }

      setFilteredData(updatedData)
    }
  }, [data, searchTerm, dimensionFilter, deprecatedFilter])

  const updateQueryParams = (newParams) => {
    const query = queryString.parse(location.search);
    const updatedQuery = { ...query, ...newParams };
    navigate(`?${queryString.stringify(updatedQuery)}`);
  };

  return (
    <>
      <List {...props} actions={<TopToolbarActions />} pagination={<></>} resource="questions" sort={{}}>
        <SearchAndFilter
          searchTerm={searchTerm}
          dimensionFilter={dimensionFilter}
          deprecatedFilter={deprecatedFilter}
          handleSearch={handleSearch}
          handleDimensionChange={handleDimensionChange}
          handleDeprecatedChange={handleDeprecatedChange}
        />
        <QuestionsDatagrid data={paginatedData} onViewDetails={handleShowDetails} />
        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} updateQueryParams={updateQueryParams}/>
      </List>
      {showDialog && <ShowQuestionDetails isOpen={showDialog} onClose={handleCloseDialog} record={currentRecord} />}
    </>
  )
}
