import React from 'react';
import { Toolbar, Button as MuiButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export const Pagination = ({ currentPage, totalPages, handlePageChange,  updateQueryParams}) => (
    <Toolbar>
        <MuiButton
            key="previous"
            onClick={() => { handlePageChange(currentPage - 1); updateQueryParams({page: currentPage - 1}) }}
            startIcon={<ChevronLeft />}
            disabled={currentPage === 1}
        >
            Previous
        </MuiButton>
        <MuiButton
            key="next"
            onClick={() => { handlePageChange(currentPage + 1); updateQueryParams({page: currentPage + 1}) }}
            startIcon={<ChevronRight />}
            disabled={currentPage === totalPages}
        >
            Next
        </MuiButton>
    </Toolbar>
);
