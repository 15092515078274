import { useState, useEffect } from 'react'
import { List, Datagrid, TextField, EditButton, useListController } from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { TopToolbarActions } from '../../components/TopToolbar'
import { usePagination } from '../../hooks/usePaginations';
import { Pagination } from '../../components/Pagination';
import { SearchField } from '../../components/SearchField';

export const SurveySetsList = (props) => {
    const { data } = useListController();
    const location = useLocation();
    const navigate = useNavigate();

    const [filteredData, setFilteredData] = useState([])
    const { paginatedData, totalPages, currentPage, handlePageChange} = usePagination(10, filteredData);
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        const query = queryString.parse(location.search);
        setSearchTerm(query.searchTerm as string || '');
        handlePageChange(query.page ? parseInt(query.page as string, 10) : 1);
        // eslint-disable-next-line
      }, [location.search]);

    const updateQueryParams = (newParams) => {
        const query = queryString.parse(location.search);
        const updatedQuery = { ...query, ...newParams };
        navigate(`?${queryString.stringify(updatedQuery)}`);
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        handlePageChange(1)
        updateQueryParams({ searchTerm: event.target.value, page: 1 });
    }

    useEffect(() => {
        if (data) {
          let updatedData = [...data]
    
          if (searchTerm) {
            updatedData = updatedData.filter(item => {
              const scopeId = item.scopeId.toLowerCase();
              const name = item.name ? item.name.toLowerCase() : '';
              const setId = item.setId.toLowerCase();
              const searchLowerCase = searchTerm.toLowerCase()

              return (
                  scopeId.includes(searchLowerCase) ||
                  name.includes(searchLowerCase) ||
                  setId.includes(searchLowerCase)
                  
              );
          });
          }
    
          setFilteredData(updatedData)
        }
      }, [data, searchTerm])
    return (
        <>
            <List {...props} actions={<TopToolbarActions />} pagination={<></>} resource='surveySets'>
            <SearchField searchTerm={searchTerm} handleSearch={handleSearch} label={"Search set"}/>
                <Datagrid data={paginatedData}>
                    <TextField source="setId" sortable={false}/>
                    <TextField source="scopeId" sortable={false}/>
                    <TextField source="name" sortable={false}/>
                    <EditButton label='Edit/Duplicate'/>
                </Datagrid>
                <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    handlePageChange={handlePageChange}
                    updateQueryParams={updateQueryParams}
                />
            </List>
        </>
    )
}